<template>
  <a @click="scrollToTop" class="scroll-to-top" :style="{
      display: topVisible ? 'inline' : 'none',
      bottom: `${scrollBottom}px`
    }"> 
    <i class="fas fa-angle-up" aria-hidden="true"></i>
  </a>
</template>
<script>
import { useVisibilityStore } from '@/assets/js/useVisibilityStore';
import { computed } from 'vue';
export default {
  name: 'ScrollToTop',
  setup() {
      const visibilityStore = useVisibilityStore();
      const visiblePlus = computed(() => visibilityStore.visiblePlus);
      const visible2020 = computed(() => visibilityStore.visible2020);
       const scrollBottom = computed(() => {
       let baseBottom = 280; // Alapértelmezett 'bottom' érték
          if (!visiblePlus.value) baseBottom -= 100; // Ha visiblePlus false
          if (!visible2020.value) baseBottom -= 100; // Ha visible2020 false
          return baseBottom;
        });
      return {
      visiblePlus,
      visible2020,
      scrollBottom
    };
  },
 
  data() {
    return {
      topVisible: false,
    };
  },
  mounted() {
    this.initializeScrollToTop();
  },
  methods: {
    initializeScrollToTop() {
      this.checkScrollPosition();
      window.addEventListener("scroll", this.checkScrollPosition);
    },
    checkScrollPosition() {
      if (window.scrollY >= 500) {
        this.topVisible = true;
      } else {
        this.topVisible = false;
      }
    },
    scrollToTop() {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  unmounted() {
    window.removeEventListener("scroll", this.checkScrollPosition);
  },
}
</script>
