<template>
    <div 
    :class="visiblePlus ? 'ginoplogo2020' : 'only-ginoplogo2020'"
    :style="{display: visible2020 ? 'block': 'none'}">
    <div class="img_wrp">
        <a :href="`${$route.meta.basePath || ''}/` + 'competention'">
            <img class="ginopimg" src="../assets/img/szechenyi-2020-logo-bottom.png" alt="Európai Uniós támogatásból megvalósuló fejlesztések">
        </a>
        <a class="close" @click="toggle">
            <i class="fa-regular fa-rectangle-xmark"></i>
        </a>
    </div>
    </div>
</template>
<script>
import { useVisibilityStore } from '@/assets/js/useVisibilityStore';
import { computed } from 'vue';
export default {
  name: 'Competention',
  setup() {
    const visibilityStore = useVisibilityStore();

    const toggle = () => {
      visibilityStore.toggle2020Visibility();
    };
    const visiblePlus = computed(() => visibilityStore.visiblePlus);
    const visible2020 = computed(() => visibilityStore.visible2020);
    return {
      toggle,
      visiblePlus,
      visible2020
    };
  },
  unmounted() {
    window.removeEventListener('scroll', this.checkScrollPosition);
  },
};
</script>