<template>
  <div class="ginoplogo" :style="{ display: visiblePlus ? 'block' : 'none' }">
    <div class="img_wrp">
      <a :href="`${$route.meta.basePath || ''}/competention`">
        <img src="../assets/img/MFF kedvezményezetti infóblokk keretes-1.jpg" alt="Európai Uniós támogatásból megvalósuló fejlesztések" />
      </a>
      <a class="close" @click="toggle">
        <i class="fa-regular fa-rectangle-xmark"></i>
      </a>
    </div>
  </div>
</template>

<script>
import { useVisibilityStore } from '@/assets/js/useVisibilityStore';
import { computed } from 'vue';
export default {
  name: 'Competention',
  setup() {
    const visibilityStore = useVisibilityStore();

    const toggle = () => {
      visibilityStore.togglePlusVisibility();
    };
    const visiblePlus = computed(() => visibilityStore.visiblePlus);
    // Adj vissza minden szükséges értéket a sablon számára
    return {
      toggle,
      visiblePlus
    };
  },
  unmounted() {
    window.removeEventListener('scroll', this.checkScrollPosition);
  },
};
</script>