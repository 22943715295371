<template>
  <div id="app" class="page">
    <router-view/>
    <CompetentionLogoPlus/>
     <CompetentionLogo2020/>
    <ScrollToTop/>
  </div>
</template>

<script>

import ScrollToTop from './components/ScrollToTop.vue'
import CompetentionLogoPlus from './components/CompetentionLogoPlus.vue'
import CompetentionLogo2020 from './components/CompetentionLogo2020.vue'
export default {
  name: 'App',
  components: {

    ScrollToTop,
    CompetentionLogoPlus,
    CompetentionLogo2020
  },
  
  metaInfo () {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale
      },
     
    }
  },
}
</script>