import { defineStore } from 'pinia';

export const useVisibilityStore = defineStore('visibility', {
  state: () => ({
    visiblePlus: true,
    visible2020: true,
  }),
  actions: {
    togglePlusVisibility() {
      this.visiblePlus = !this.visiblePlus;
    },
    setPlusVisibility(value) {
      this.visiblePlus = value;
    },
    toggle2020Visibility() {
        this.visible2020 = !this.visible2020;
      },
      set2020Visibility(value) {
        this.visible2020 = value;
      },
  },
});